import { Endpoint, RequestMethod } from "../../dao";

export const SKYXPLORE_GAME_GET_GAME_ID = new Endpoint(RequestMethod.GET, "/api/skyxplore/game");

export const SKYXPLORE_GAME_SAVE = new Endpoint(RequestMethod.POST, "/api/skyxplore/game");
export const SKYXPLORE_GAME_PAUSE = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/pause");
export const SKYXPLORE_EXIT_GAME = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game");
export const SKYXPLORE_GAME_GET_CHAT_ROOMS = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/chat/room");
export const SKYXPLORE_GAME_GET_PLAYERS = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/player");
export const SKYXPLORE_GAME_CREATE_CHAT_ROOM = new Endpoint(RequestMethod.PUT, "/api/skyxplore/game/chat/room");
export const SKYXPLORE_GAME_LEAVE_CHAT_ROOM = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/chat/room/{roomId}");
export const SKYXPLORE_GAME_MAP = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/universe");
export const SKYXPLORE_GET_SOLAR_SYSTEM = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/solar-system/{solarSystemId}");
export const SKYXPLORE_SOLAR_SYSTEM_RENAME = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/solar-system/{solarSystemId}/name");
export const SKYXPLORE_PLANET_RENAME = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/planet/{planetId}/name");
export const SKYXPLORE_PLANET_GET_OVERVIEW = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/planet/{planetId}/overview");
export const SKYXPLORE_PLANET_UPDATE_PRIORITY = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/planet/{planetId}/priority/{priorityType}");
export const SKYXPLORE_BUILDING_DECONSTRUCT = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/building/{planetId}/{buildingId}/deconstruct");
export const SKYXPLORE_BUILDING_CANCEL_DECONSTRUCTION = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/building/{planetId}/{buildingId}/deconstruct");
export const SKYXPLORE_BUILDING_UPGRADE = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/building/{planetId}/{buildingId}");
export const SKYXPLORE_BUILDING_CANCEL_CONSTRUCTION = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/building/{planetId}/{buildingId}");
export const SKYXPLORE_PLANET_GET_POPULATION = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/planet/{planetId}/citizen");
export const SKYXPLORE_PLANET_RENAME_CITIZEN = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/citizen/{citizenId}/rename");
export const SKYXPLORE_PLANET_GET_STORAGE_SETTINGS = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/planet/{planetId}/storage-settings");
export const SKYXPLORE_PLANET_CREATE_STORAGE_SETTING = new Endpoint(RequestMethod.PUT, "/api/skyxplore/game/planet/{planetId}/storage-settings");
export const SKYXPLORE_PLANET_EDIT_STORAGE_SETTING = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/storage-settings");
export const SKYXPLORE_PLANET_DELETE_STORAGE_SETTING = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/storage-settings/{storageSettingId}");
export const SKYXPLORE_GAME_IS_HOST = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/host");
export const SKYXPLORE_BUILDING_CONSTRUCT_NEW = new Endpoint(RequestMethod.PUT, "/api/skyxplore/game/building/{planetId}/{surfaceId}");
export const SKYXPLORE_PLANET_SET_QUEUE_ITEM_PRIORITY = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/{planetId}/{type}/{itemId}/priority");
export const SKYXPLORE_PLANET_CANCEL_QUEUE_ITEM = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/{planetId}/{type}/{itemId}");
export const SKYXPLORE_GAME_TERRAFORM_SURFACE = new Endpoint(RequestMethod.POST, "/api/skyxplore/game/surface/{planetId}/{surfaceId}/terraform");
export const SKYXPLORE_GAME_CANCEL_TERRAFORMATION = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/{planetId}/{surfaceId}/terraform");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCT_CONSTRUCTION_AREA = new Endpoint(RequestMethod.PUT, "/api/skyxplore/game/surface/{surfaceId}/construction-area");
export const SKYXPLORE_PLANET_SURFACE_CANCEL_CONSTRUCTION_AREA_CONSTRUCTION = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/construction-area/{constructionId}/cancel-construction");
export const SKYXPLORE_PLANET_SURFACE_DECONSTRUCT_CONSTRUCTION_AREA = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/construction-area/{constructionAreaId}");
export const SKYXPLORE_PLANET_SURFACE_CANCEL_DECONSTRUCT_CONSTRUCTION_AREA = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/construction-area/{deconstructionId}/cancel-deconstruction");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCTION_AREA_GET_BUILDING_MODULES = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/surface/construction-area/{constructionAreaId}/building-module");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCTION_AREA_DECONSTRUCT_BUILDING_MODULE = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/construction-area/building-module/{buildingModuleId}/deconstruct");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCTION_AREA_CANCEL_DECONSTRUCTION_OF_BUILDING_MODULE = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/construction-area/building-module/{deconstructionId}/cancel-deconstruction");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCTION_AREA_AVAILABLE_BUILDING_MODULES = new Endpoint(RequestMethod.GET, "/api/skyxplore/game/surface/construction-area/{constructionAreaId}/building-modules/{buildingModuleCategory}");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCTION_AREA_CONSTRUCT_BUILDING_MODULE = new Endpoint(RequestMethod.PUT, "/api/skyxplore/game/surface/construction-area/{constructionAreaId}/building-module");
export const SKYXPLORE_PLANET_SURFACE_CONSTRUCTION_AREA_CANCEL_CONSTRUCTION_OF_BUILDING_MODULE = new Endpoint(RequestMethod.DELETE, "/api/skyxplore/game/surface/construction-area/building-module/{constructionId}/cancel-construction");