const PageName = {
    MAP: "map",
    SOLAR_SYSTEM: "solar-system",
    PLANET: "planet",
    MODIFY_SURFACE: "modify-surface",
    POPULATION: "population",
    STORAGE: "storage",
    CONSTRUCTION_AREA: "construction-area",
    CONSTRUCT_BUILDING_MODULE: "CONSTRUCT_BUILDING_MODULE",
}

export default PageName;