import React from "react";

const EliteBaseOverviewPage = ({ }) => {
    return (
        <div className="elite-base-page">
            Overview
        </div>
    );
}

export default EliteBaseOverviewPage;