const CitizenAssignmentType = {
    IDLE: "IDLE",
    CONSTRUCT_CONSTRUCTION_AREA: "CONSTRUCT_CONSTRUCTION_AREA",
    DECONSTRUCT_CONSTRUCTION_AREA: "DECONSTRUCT_CONSTRUCTION_AREA",
    CONSTRUCT_BUILDING_MODULE: "CONSTRUCT_BUILDING_MODULE",
    DECONSTRUCT_BUILDING_MODULE: "DECONSTRUCT_BUILDING_MODULE",
    REST: "REST",
    TERRAFORMATION: "TERRAFORMATION",
    PRODUCTION_ORDER: "PRODUCTION_ORDER",
    STORAGE_SETTING: "STORAGE_SETTING",
}

export default CitizenAssignmentType;